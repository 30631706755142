.fixed-column {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
}

.fixed-actions {
    position: sticky;
    right: 0;
    background: white;
    z-index: 1;
}

.table-container {
    overflow-x: auto;
}

.table-container table {
    border-collapse: collapse;
    width: 100%;
}

.table-container th,
.table-container td {
    padding: 12px 15px;
    text-align: left;
    white-space: nowrap;
}

.table-container thead th {
    background: #f1f1f1;
}

.table-container tbody tr:nth-child(even) {
    background: #f9f9f9;
}
