* {
    font-family: "Poppins", sans-serif;
}


.bg-primary {
    /* background-color: #000080; */
    background-color: #000080;
}

.bg-primary-dark {
    /*background-color: #001a3f;*/
    background-color: #001a3f;
}

.color-primary {
    color: #000080;
}

.color-primary-dark {
    color: #001a3f;
}

.header-signup-button {
    color: white !important;
}

.bg-min-height{
    min-height: 42vh;
}

.section-header {
    font-family: Poppins;
    text-transform: none;
    letter-spacing: 0;
    font-size: 30px !important;
    line-height: 52px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    /* Display as inline-block */
}

.section-header-secondary {
    font-family: Poppins;
    text-transform: none;
    letter-spacing: 0;
    font-size: 20px;
    line-height: 52px;
    font-weight: 700;
    position: relative;
    align-items: center;
    display: inline-block;
    /* Display as inline-block */
}

.section-header-white {
    font-family: Poppins;
    text-transform: none;
    letter-spacing: 0;
    font-size: 40px;
    line-height: 52px;
    font-weight: 700;
    position: relative;
    color: white;
    display: inline-block;
    /* Display as inline-block */
}

.section-header::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    /* Set width to 100% */
    height: 2px;
    background-color: #000080;
    display: inline-block;
    /* Display as inline-block */
}


.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
}

/* NAVBAR */
.drop-shadow {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1),
    0px 1px 3px rgba(0, 0, 0, 0.08);
}

.footer-shadow {
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1), 0px -1px 3px rgba(0, 0, 0, 0.08); /* Negative values for Y-axis to move shadow above */
}

.navbar-links > ul > li > a,
.navbar-links-button {
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    letter-spacing: 0;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
}

.navbar-dropdown-container > ul > li > a {
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    letter-spacing: 0;
    font-size: 16px;
    line-height: 16px;
}

.navbar-dropdown-container > ul > li > a:hover {
    background-color: #000080 !important;
    color: white;
}

header a.active {
    color: #000080;
}

header a:hover {
    color: #000080;
}

/* NAVBAR */

.footer {
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
}

.footer-section {
    flex: 1 1 25%;
    padding: 10px;
    color: white;
    text-align: left;
}

.footer-section h3 {
    font-size: 16px;
    margin-bottom: 10px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 5px;
}

/* .footer-section:first-child p {
  font-size: 12px;
} */

.footer-section .first-p {
    font-size: 13px;
    padding-top: 5px;
    padding-bottom: 20px;
}

.footer-section .second-p {
    font-size: 16px;
    color: #000080;
}

.heading-section {
    color: #000080;
}


.slick-dots {
    bottom: 0px !important;
}


.main-content:hover {
    box-shadow: 0 0 10px 10px rgba(196, 195, 195, 0.3);
    /* Add the box-shadow effect */
}


/* FOOTER */
footer {
    font-weight: 300 !important;
    font-size: 16px;
    font-family: 'Poppins';
}

.underline-design {
    display: inline-block;
    position: relative;
    padding-bottom: 20px !important;
    margin-bottom: 30px !important;
    font-size: 22px !important;
    text-transform: uppercase !important;
    color: #000080 !important;
}

.underline-design::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: grey;
}

.footer-link {
    margin-top: 20px;
}

.footer-quick-links {
    line-height: 2;
}

/* FOOTER */


/*MEMBER*/
.member-form-heading {
    background-color: black;
    color: white;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    letter-spacing: 0;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 40px !important;
}

/*MEMBER*/


/* REWARD INFO */
.icon-container {
    width: 60px;
    /* Adjust size as needed */
    height: 60px;
    /* Adjust size as needed */
    border: 2px solid white;
}

/* REWARD INFO */


/*HOME TESTIMONIAL SLIDER*/
.swiper {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.swiper-slide {
    background-size: cover;
    max-width: 350px;
    height: auto;
    aspect-ratio: 1;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.testimonial-icon {
    font-size: 100px;
}

.testimonial-content {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*HOME TESTIMONIAL SLIDER*/


/*Plan pricing*/
.list-insidetext-left > span,
.list-inside > span {
    margin-bottom: 10px;
}

/*Plan pricing*/


/* discount screen */


.exclusive-offers {
    height: 30vh;
    background-color: #000080;
    border: 2px solid blue;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.top-header {
    width: 100%;
}


@media (max-width: 767px) {

    /* Your CSS styles for screens with a maximum width of 767px */
    /* For example: */
    .left-menu {
        margin-top: 10;
        /* width: 100%; */
    }
}

.about-us-page p,
.terms-conditions-page p,
.privacy-policy-page p {
    font-size: 16px;
    text-align: justify;
    color: "#666";
    line-height: 1.6;
}

.terms-conditions-p-text,
.privacy-policy-p-text {
    color: "#666";
    line-height: 1.6;
}

.bold-paragraph {

}

.list-text {
    color: "#666";
    line-height: 1.6;
    list-style-type: disc;
    margin-left: "20px";
}

.list-text-digit {
    color: "#666";
    line-height: 1.6;
    /* list-style-type: "digit"; */
    /* margin-left: "20px"; */
}

.terms-privacy-table {
    border: 1px solid #dddddd;
    padding: 8px;
    /* style={{ border: "1px solid #dddddd", padding: "8px" }} */
}

.terms-conditions-p-heading,
.privacy-policy-p-heading {
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
}

.membership-rcm-header {
    font-size: 20px !important;
}

.about-us-page h2,
.terms-conditions-page h2,
.privacy-policy-page h2 {
    font-size: 18px;
    /* text-align: justify; */

}

.about-us-page li,
.terms-conditions-page li,
.privacy-policy-page li {
    font-size: 16px;
    text-align: justify;
}

.about-text p {
    font-size: 16px;
}


.about-bg {
    width: 100%;
}

.find-coupon h2 {
    font-size: 16px;
    padding: 5px;
    font-weight: bold;
}

.privacy-member-table-bg {
    background-color: "#f2f2f2";
}

.aboud-bg-main,
.terms-condition-bg-main,
.privacy-policy-bg-main {

    height: auto;
    width: 100%;
    background-color: white;
    padding-top: 2%;
}

.aboud-bg-inner,
.terms-condition-bg-inner,
.privacy-policy-bg-inner {
    height: auto;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    min-height: 100vh;
}

.aboud-bg-inner-style,
.terms-condition-bg-inner-style,
.privacy-policy-bg-inner-style {
    color: "#333";
    display: flex;
    flex-direction: column;
    text-align: left;
}

/* end discount screen  */


.privacy-pocily-table ul li, .privacy-policy-page ul li, .terms-conditions-page ul li {
    margin-left: 20px;
}


.discount-page {
    background-color: #eee;
}


.user-icons-navbar ul li {
    
}

.font-style{
    font-family: "Poppins", sans-serif;
}

.footer-payment-logos {
    width: auto;
    height: 22px;
    margin-right: 10px;
}


.react-multiple-carousel__arrow {
    z-index: 998 !important;
}


/* car image moving  */


@keyframes carMovement {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(330%);
    }
}
/* 
.animate-carMovement {
    animation: carMovement 5s linear infinite;
} */

/* 
@keyframes carMovement {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media (prefers-reduced-motion: no-preference) {
    .animate-carMovement {
      animation: enter-animation 0.8s 1;
    }
  } */

  .plan-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  } 

/* Add this to your CSS file */
.modal-container {
    position: fixed;
    inset: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.75); /* semi-transparent background */
  }
  
  .modal-content {
    position: relative;
    margin: 20px;
    background: white;
    border-radius: 8px;
    max-width: 400px; /* Adjust as needed */
    width: 100%;
    /* padding: 20px; */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  