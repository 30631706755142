/* MasterHeader.css */

.header {
    height: 300px;
    background: linear-gradient(135deg, #750bff, #0060c7, #00459e, #007bff, #8337e6);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    color: white;
}

.header-black{
    color: black;
}

.font-large {
    font-size: 2rem; /* Adjust the value as needed */
}

.auto-header {
    height: auto !important;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
